import brandActionTypes from 'redux/actionTypes/Brand';
import rootActionTypes from 'redux/actionTypes/root';
import imageUploadActionTypes from 'redux/actionTypes/imageUpload';
import userActionTypes from 'redux/actionTypes/User';
import categoryActionTypes from 'redux/actionTypes/Category';
import cartActionTypes from 'redux/actionTypes/Cart';
import productActionTypes from 'redux/actionTypes/Product';

const actionTypes = {
  ...brandActionTypes,
  ...rootActionTypes,
  ...imageUploadActionTypes,
  ...userActionTypes,
  ...categoryActionTypes,
  ...cartActionTypes,
  ...productActionTypes,
};

export default actionTypes;
