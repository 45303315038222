import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';

// redux
import { Provider } from 'react-redux';
import store from 'store';

//gql
import { ApolloProvider } from '@apollo/react-hooks';
import client from 'gqlClient';

// styles
import 'assets/css/nucleo-icons.css';
import 'assets/scss/blk-design-system-pro-react.scss?v1.0.0';
import 'assets/demo/demo.css';
import 'assets/demo/react-demo.css';

import Loading from 'views/LoadingScreen';
import * as serviceWorker from 'serviceWorker';
require('dotenv').config();

document.getElementById('loading-container').remove();

const App = lazy(() => import('components/App'));

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <Suspense fallback={<Loading />}>
        <App />
      </Suspense>
    </Provider>
  </ApolloProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
