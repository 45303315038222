import actionTypes from 'redux/actionTypes';

const { PRODUCTS_PAGE_LOAD_MORE } = actionTypes;
const initialState = {
  productsPage: {
    limit: 4,
    skip: 0,
    products: [],
  },
};

const reducer = (state = initialState, { type, payload }) => {
  const newState = Object.assign({}, state);
  switch (type) {
    case PRODUCTS_PAGE_LOAD_MORE:
      newState.productsPage = Object.assign({}, state.productsPage, {
        skip: state.productsPage.skip + state.productsPage.limit,
      });
      break;
    default:
      break;
  }
  return newState;
};

export default reducer;
