const initialState = {
  categories: [],
};

const reducer = async (state = initialState, { type, payload, cb }) => {
  const newState = { ...state };
  switch (type) {
    default:
      break;
  }
  return newState;
};

export default reducer;
