import actionTypes from 'redux/actionTypes';
const {
  ADD_TO_CART,
  INCREMENT_QUANTITY,
  DECREMENT_QUANTITY,
  REMOVE_FROM_CART,
  POPULATE_CART,
} = actionTypes;

const initialState = {
  cartItems: [],
};

const isInCart = (cart, id, size = null) => {
  let index;
  if (!size) {
    index = cart.cartItems.findIndex(item => item.id === id);
  } else {
    index = cart.cartItems.findIndex(
      item => item.id === id && item.size === size,
    );
  }
  return index;
};

const reducer = (state = initialState, action) => {
  let newState = Object.assign({}, state);
  let index = -1;
  let newCartItems;
  let currentItem;

  switch (action.type) {
    case ADD_TO_CART:
      const { id, size } = action.payload;
      index = isInCart(newState, id, size);
      if (index >= 0) {
        const cartItem = newState.cartItems[index];
        const newCartItem = Object.assign({}, cartItem, {
          quantity: (cartItem.quantity += action.payload.quantity),
        });
        newState.cartItems[index] = newCartItem;
        newCartItems = [...newState.cartItems];
      } else {
        newCartItems = [...newState.cartItems, action.payload];
      }
      newState = Object.assign({}, state, { cartItems: newCartItems });
      localStorage.setItem('cart', JSON.stringify(newState));
      break;
    case INCREMENT_QUANTITY:
      currentItem = action.payload;
      index = newState.cartItems.findIndex(cartItem => {
        if (currentItem.size) {
          return (
            currentItem.size === cartItem.size && currentItem.id === cartItem.id
          );
        } else {
          return cartItem.id === currentItem.id;
        }
      });
      if (index >= 0) {
        const newCartItem = Object.assign({}, newState.cartItems[index], {
          quantity: newState.cartItems[index].quantity + 1,
        });
        newState.cartItems[index] = newCartItem;
      }
      localStorage.setItem('cart', JSON.stringify(newState));
      break;
    case DECREMENT_QUANTITY:
      currentItem = action.payload;
      index = newState.cartItems.findIndex(cartItem => {
        if (currentItem.size) {
          return (
            currentItem.size === cartItem.size && currentItem.id === cartItem.id
          );
        } else {
          return cartItem.id === currentItem.id;
        }
      });
      if (index >= 0) {
        const newCartItem = Object.assign({}, newState.cartItems[index], {
          quantity: newState.cartItems[index].quantity - 1,
        });
        newState.cartItems[index] = newCartItem;
      }
      localStorage.setItem('cart', JSON.stringify(newState));
      break;
    case REMOVE_FROM_CART:
      currentItem = action.payload;
      newCartItems = newState.cartItems.filter(cartItem => {
        if (currentItem.size) {
          return !(
            cartItem.size === currentItem.size && cartItem.id === currentItem.id
          );
        } else {
          return cartItem.id !== currentItem.id;
        }
      });
      newState = Object.assign({}, state, { cartItems: newCartItems });
      localStorage.setItem('cart', JSON.stringify(newState));
      break;
    case POPULATE_CART:
      const cartFromStorage = localStorage.getItem('cart');
      if (cartFromStorage) {
        newState = JSON.parse(cartFromStorage);
      }
      break;
    default:
      break;
  }
  return newState;
};

export default reducer;
