const USER_LOGIN = 'USER_LOGIN';
const USER_REGISTER = 'USER_REGISTER';
const SET_USER = 'SET_USER';
const USER_AUTHENTICATE = 'USER_AUTHENTICATE';
const USER_LOGOUT = 'USER_LOGOUT';

const actionTypes = {
  USER_LOGIN,
  USER_REGISTER,
  SET_USER,
  USER_LOGOUT,
  USER_AUTHENTICATE,
};

export default actionTypes;
