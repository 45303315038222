import actionTypes from 'redux/actionTypes';
import jwt from 'jsonwebtoken';

const {
  USER_LOGIN,
  USER_REGISTER,
  SET_USER,
  USER_AUTHENTICATE,
  USER_LOGOUT,
} = actionTypes;

const initialState = {
  user: null,
  error: null,
};

const authorization = (state = initialState, action) => {
  const newState = Object.assign({}, state);
  let token;
  switch (action.type) {
    case USER_LOGIN:
      const { email, password } = action.payload;
      action
        .cb({
          variables: {
            email,
            password,
          },
        })
        .catch(e => (newState.error = e));
      break;
    case USER_REGISTER:
      break;
    case SET_USER:
      token = action.payload;
      try {
        const { data } = jwt.verify(
          token,
          process.env.REACT_APP_SECRET_LOGIN_TOKEN,
        );
        newState.user = data;
      } catch (e) {
        console.log(e);
      }
      break;
    case USER_AUTHENTICATE:
      try {
        token = action.payload;
        const { data } = jwt.verify(
          token,
          process.env.REACT_APP_SECRET_LOGIN_TOKEN,
        );
        newState.user = data;
      } catch (e) {
        console.log(e);
      }
      break;
    case USER_LOGOUT:
      try {
        localStorage.removeItem('token');
        newState.user = null;
      } catch (err) {
        newState.error = err;
        console.log(err);
      }
      break;
    default:
      break;
  }
  return newState;
};

export default authorization;
