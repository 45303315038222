const initialState = {
  brands: [],
};

const brandReducer = async (state = initialState, { type, payload, cb }) => {
  const newState = { ...state };
  switch (type) {
    default:
      break;
  }
  return newState;
};

export default brandReducer;
