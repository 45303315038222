import types from 'redux/actionTypes';

const { LOADING, FINISHED_LOADING } = types;

const initialState = {
  isLoading: false,
};

const rootReducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case LOADING:
      newState.isLoading = true;
      break;
    case FINISHED_LOADING:
      newState.isLoading = false;
      break;
    default:
      break;
  }
  return newState;
};

export default rootReducer;
