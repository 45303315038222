import { combineReducers } from 'redux';

import brand from 'redux/reducers/Brand';
import rootReducer from 'redux/reducers/root';
import imageUpload from 'redux/reducers/imageUpload';
import user from 'redux/reducers/User';
import category from 'redux/reducers/Category';
import cart from 'redux/reducers/Cart';
import product from 'redux/reducers/Product';

const allReducers = combineReducers({
  brand,
  user,
  imageUpload,
  category,
  cart,
  product,
  root: rootReducer,
});

export default allReducers;
