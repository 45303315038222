import React, { Fragment, useEffect } from 'react';

// reactstrap components
import { Container, Row, Col, Spinner } from 'reactstrap';

// core components

export default props => {
  useEffect(() => {
    document.body.classList.add('loading-page');
    return () => document.body.classList.remove('loading-page');
  }, []);
  return (
    <Fragment>
      <div className={`${!props.section && 'page-header'} loading-page`}>
        <Container>
          <Row>
            <Col className="align-self-center" md="12">
              <h4>Loading</h4>
              <Spinner
                color="light"
                style={{ width: '10em', height: '10em' }}
                type="grow"
                size="lg"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};
